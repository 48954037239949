@import "@campaign-portal/components-library/src/styles/utils/common";

.table-management {
	@extend %as-flex-center;
	position:        relative;
	flex-wrap:       wrap;
	justify-content: space-between;
	padding:         var(--as-root-padding);
	gap:             1rem;

	.left {
		@extend %as-flex-center;
		flex-wrap:       wrap;
		justify-content: flex-start;
		justify-self:    flex-start;
		gap:             1rem;
	}

	.right {
		@extend %as-flex-center;
		flex-wrap:       wrap;
		justify-content: flex-end;
		justify-self:    flex-end;
		gap:             1rem;
		margin-left:     auto
	}

	.indicator {
		width:            0.5rem;
		height:           0.5rem;
		border-radius:    50%;
		background-color: var(--as-IconAccent);
	}
}
