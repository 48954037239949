@import "@campaign-portal/components-library/src/styles/utils/common";

.chart-wrapper {
	position:      relative;
	display:       flex;
	align-items:   center;
	width:         100%;
	height:        100%;
	justify-items: center;

	.chart {
		width:  100%;
		height: 100%;
	}
}

.stub-chart {
	--tui-chart-0: var(--as-BgSecondary);
}

.stub-chart-note {
	position:         absolute;
	top:              25%;
	right:            0;
	left:             0;
	width:            fit-content;
	min-width:        10rem;
	max-width:        100%;
	margin-right:     auto;
	margin-left:      auto;
	padding:          1.5rem 2.75rem;
	text-align:       center;
	color:            var(--as-TextSecondary);
	background-color: var(--as-OpacityAlpha-1);

	backdrop-filter:  blur(3px);
}

.chart-tooltip {
	@include TextStyleBodyBody();
	display:        flex;
	align-items:    center;
	flex-direction: column;
	width:          100%;

	.date {
		align-self:     start;
		padding-bottom: 0.75rem;
		color:          var(--as-TextContrastAlpha-50);
	}

	.flex {
		display:        flex;
		flex-direction: column;
		width:          100%;
		gap:            1rem;

		.grid {
			display:               grid;
			align-items:           center;
			justify-content:       start;
			width:                 100%;
			grid-template-columns: 0.5rem 1.5fr 1fr 1fr;
			grid-column-gap:       0.75rem;
			white-space:           nowrap;

			& > * {
				height: 1.5rem;
			}

			&.three-column {
				grid-template-columns: 0.5rem 1.5fr 1fr;
			}

			&.four-column {
				grid-template-columns: 0.5rem 1.5fr 1fr 1fr;
			}

			.dot {
				width:         0.5rem;
				height:        0.5rem;
				border-radius: 50%;
			}

			& .last {
				@include TextStyleBodyBodyBold();
				text-align: end;
			}
		}
	}

}

%with-seen {
	--tui-chart-0: var(--as-Blue-Light-400); // SENT
	--tui-chart-1: var(--as-Green-500); // DELIVERED
	--tui-chart-2: var(--as-Violet-400); // SEEN
	--tui-chart-3: var(--as-Gray-300); // UNDELIVERED
	--tui-chart-4: var(--as-Red-450); // FAILED


	&.dark {
		--tui-chart-0: var(--as-Blue-Light-500);
		--tui-chart-1: var(--as-Green-600);
		--tui-chart-2: var(--as-Violet-600);
		--tui-chart-3: var(--as-Gray-400);
		--tui-chart-4: var(--as-Red-Light-650);
	}

}

%without-seen {
	--tui-chart-0: var(--as-Blue-Light-400); // SENT
	--tui-chart-1: var(--as-Green-500); // DELIVERED
	--tui-chart-2: var(--as-Gray-300); // UNDELIVERED
	--tui-chart-3: var(--as-Red-450); // FAILED

	&.dark {
		--tui-chart-0: var(--as-Blue-Light-500);
		--tui-chart-1: var(--as-Green-600);
		--tui-chart-2: var(--as-Gray-400);
		--tui-chart-3: var(--as-Red-Light-650);
	}

}

