alaris-tabs {
	.tabs {
		margin-left: .5rem;
	}
}

alaris-card.table > .card[size=auto] {
	min-height: 0;
}

.edit-panel-footer {
	border-radius: var(--as-border-radius-L);
}

.loader-backdrop {
	opacity:          0.6 !important;
	background-color: var(--as-BgSecondary);
}

.alaris-content {
	height: calc(100% - var(--as-navbar-height) - 2rem);

	&.overflow {
		height: unset;
	}
}
