@import "@campaign-portal/components-library/src/styles/utils/common";

/* taiga-ui */
:root {
	--tui-font-text:     var(--as-font-family) !important;
	--tui-font-text-xs:  var(--as-font-family) !important;
	--tui-font-text-s:   var(--as-font-family) !important;
	--tui-font-text-m:   var(--as-font-family) !important;
	--tui-font-text-l:   var(--as-font-family) !important;
	--tui-font-text-xl:  var(--as-font-family) !important;
	--tui-text-01:       var(--as-TextPrimary) !important;
	--tui-text-01-night: var(--as-TextPrimary) !important;
	--tui-text-02:       var(--as-TextPrimary) !important;
	--tui-text-02-night: var(--as-TextPrimary) !important;
	--tui-text-03:       var(--as-TextPrimary) !important;
	--tui-text-03-night: var(--as-TextPrimary) !important;
	--tui-base-01:       var(--as-BgSecondaryExtra) !important;
	--tui-base-02:       var(--as-BgSecondaryExtra) !important;
	--tui-base-03:       var(--as-BgSecondaryExtra) !important; //green !important; // var(--as-TextContrastThemed) !important;
}

.t-button {
	width: 100% !important;

	&[data-size="s"] {
		height:     auto !important;
		min-height: var(--tui-height-s);
	}

	.t-wrapper {
		.t-content {
			.t-wrapper {
				display:               grid;
				align-items:           center;
				width:                 100%;
				grid-template-rows: 1fr;
				grid-template-columns: 0.5rem 1fr auto;

				.t-text {
					display:            -webkit-box;
					overflow:           hidden;
					text-align:         start;
					word-break:         break-word;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
				}
			}
		}
	}

}

.t-wrapper_hoverable:hover, .t-wrapper_hoverable.t-wrapper_hovered {
	filter: brightness(0.8);
}

tui-hints-host {
	z-index:        1;
	--tui-primary:  var(--as-BgTooltip);
	--tui-radius-l: 0.25rem;
}

tui-ring-chart {
	color: var(--as-BgTertiary);
}

tui-axes {
	.t-label-x {
		text-align:  center;
		white-space: nowrap;
		@include TextStyleBodyBodyS();
		color:       var(--as-TextSecondary)
	}

	.t-label-y:last-child {
		margin-top: 0 !important;
	}

	.t-label-y {
		@include TextStyleBodyBodyS();
		color: var(--as-FgTertiary)
	}
}


/* End of taiga-ui */
